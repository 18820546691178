<template>
    <form action="">
                <div class="modal-card" style="min-width: 40vw; width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Login</p>
                        <button
                            type="button"
                            class="delete"
                            @click="close()"/>
                    </header>
                    <section class="modal-card-body">
                        <div class="field">
                             <b-tag v-if="loginErrorOccurred"
                                type="is-danger"
                                closable
                                closeType='is-danger'
                                aria-close-label="Close tag"
                                @close="loginErrorOccurred = false">
                                {{ loginErrorMessage }}
                            </b-tag>
                        </div>
                        <b-field label="Email/Username" :type="{ 'is-danger': loginErrorOccurred}">
                            <b-input
                                type="text"
                                v-model="username"
                                placeholder="Your username"
                                required>
                            </b-input>
                        </b-field>

                        <b-field label="Password" :type="{ 'is-danger': loginErrorOccurred}">
                            <b-input
                                type="password"
                                v-model="password"
                                password-reveal
                                placeholder="Your password"
                                required>
                            </b-input>
                        </b-field>

                    </section>
                    <footer class="modal-card-foot">
                        <button class="button" type="button" @click="close()">Close</button>
                        <button class="button is-primary ld-ext-right" :class="{ 'running': isLoading}" @click="onSubmit()" :disabled="isLoading">Login
                            <div class="ld ld-ring ld-spin-fast"></div>
                        </button>
                    </footer>
                </div>
            </form>
</template>
<script>
import authService from '@/core/services/auth.service'
export default {
    name : "LoginFormModal",
    data : function(){
        return {
            username : null,
            password : null,
            loginErrorOccurred : false,
            loginErrorMessage : "Ops! something went wrong",
            isLoading : false
        }
    },
    methods : {
        onSubmit(){
                this.isLoading = true;
                this.loginErrorOccurred = false;
                authService.login({ username : this.username, password : this.password})
                .then(response => {
                    console.log(response)
                    this.$emit('success')
                    this.isLoading = false;
                }).catch(error => {
                    console.log(error, "an error")
                    if(error.response.status == 401){
                        this.loginErrorMessage = "Invalid credentials"
                    }
                    this.loginErrorOccurred = true;
                    this.$emit('failed')
                    this.isLoading = false;
                })
        },
        close(){
            this.$parent.close()
            this.$emit('close')
        }
    }
}
</script>