<template>
  <div class="page-wrapper bg-voicemed">
    <section class="hero py-5">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-capitalized has-text-primary">
            VoiceMed Survey
          </h1>
          <br />
          <p class="subtitle">{{ $t("survey.index.subtitle") }}</p>
          <b-field label="Select Survey Option">
            <b-select
              placeholder="Select an option"
              v-model="surveyOption"
              size="is-medium"
              rounded
            >
              <option value="covid">Covid19</option>
              <!-- <option value="lung-cancer">lung-cancer</option> -->
            </b-select>
          </b-field>
        </div>
      </div>
      <!-- Hero footer: will stick at the bottom -->
      <div class="hero-foot">
        <nav class="tabs">
          <div class="container">
            <div class="buttons is-centered">
              <b-button
                size="is-large"
                :disabled="surveyOption == null"
                class="button is-primary is-uppercase"
                @click="onProceed()"
              >
                {{ $t("survey.index.buttons.proceed") }}
              </b-button>
              <!-- <button
                :disabled="surveyOption == null"
                class="button is-primary is-outlined is-uppercase"
                @click="processAnonymous()"
              >
                {{ $t("survey.index.buttons.anonymous") }}
              </button> -->
            </div>
          </div>
        </nav>
      </div>
    </section>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <template>
        <login-form-modal
          @close="closeModal()"
          @success="goToSurveyPage()"
        ></login-form-modal>
      </template>
    </b-modal>
    <b-loading
      :is-full-page="true"
      :active.sync="urlLogin"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import LoginFormModal from "@/core/components/ui/LoginFormModal";
import authService from "../../../core/services/auth.service";
import { mapGetters } from "vuex";
import jwtService from "../../../core/services/jwt.service";
export default {
  name: "SurveyIndex",
  data: function() {
    return {
      isLoading: false,
      surveyOption: null,
      isComponentModalActive: false,
      urlLogin: false
    };
  },
  created() {
    if (this.$route.query.qs) {
      this.urlLogin =
        this.$route.query.id && this.$route.query.hash ? true : false;

      if (this.urlLogin) {
        this.surveyOption = this.$route.query.tag || "covid";
        this.processUrlLogin({
          id: this.$route.query.id,
          hash: this.$route.query.hash
        });
      }
    }
  },
  components: {
    LoginFormModal
  },
  computed: {
    ...mapGetters(["getIsLoggedIn"])
  },
  methods: {
    goToSurveyPage() {
      this.$router.push(
        `/${this.$store.state.locale}/survey/${this.surveyOption}`
      );
    },
    onProceed() {
      if (jwtService.isTokenExpired()) {
        this.isComponentModalActive = true;
        return;
      }
      this.goToSurveyPage();
    },
    closeModal() {},
    processUrlLogin(credentials) {
      authService
        .quickUrlLogin(credentials)
        .then(() => {
          this.goToSurveyPage();
          this.urlLogin = false;
        })
        .catch(() => {
          this.urlLogin = false;
          this.$buefy.dialog.alert("!Ops something went wrong");
        });
    }
  }
};
</script>
<style>
.select select {
  min-width: 300px;
}
</style>
